import { FC } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
import logoImg from '../../images/logo.svg';
import { useTranslation } from 'react-i18next';
import paymentError from './images/unknownError.svg';
import { PageDialog } from '@/ui-kit/components';

const useStyles = makeStyles((theme: Theme) => ({
  btnControl: {
    height: theme.spacing(6),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.875rem',
    borderRadius: theme.spacing(1.25),
    textTransform: 'inherit',
  },
}));

export const UnknownErrorPopup: FC<{ onClose: () => void }> = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation('payments');
  return (
    <PageDialog open onClose={onClose} closeVariant='cross'>
      <img src={logoImg} alt='' />
      <Paper
        elevation={3}
        square
        sx={({ spacing }) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: spacing(2),
          pb: spacing(3.5),
          mb: spacing(6),
          mt: spacing(5),
        })}
      >
        <img src={paymentError} alt={t('Payment error')} />
      </Paper>
      <Paper
        elevation={3}
        square
        sx={({ spacing }) => ({
          width: '100%',
          borderRadius: spacing(2),
          p: spacing(3),
        })}
      >
        <Typography variant='h3' color='black' mb={3}>
          {t('Ooops...')}
        </Typography>
        <Typography variant='body2' color='secondary' mb={2}>
          {t('Something seems to gone wrong :(')}
        </Typography>

        <Typography variant='body2' color='secondary' mb={4}>
          {t('Please try again later')}
        </Typography>
        <Button
          className={classes.btnControl}
          sx={({ spacing }) => ({
            mb: spacing(1),
          })}
          variant='contained'
          fullWidth
          color='primary'
          onClick={onClose}
        >
          {t('Done')}
        </Button>
      </Paper>
    </PageDialog>
  );
};

import React, { FC } from 'react';

export const SuccessBanner: FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width={383} height={164} viewBox='0 0 383 164' fill='none' {...props}>
            <path
                d='M296.486 55.09c-7.553 2.834-15.105 5.64-22.658 8.475-1.154.44-.149 1.706 1.004 1.293 7.553-2.834 15.106-5.64 22.659-8.475 1.153-.44.148-1.733-1.005-1.293zM286.962 108.443c-4.577-1.843-9.153-3.659-13.766-5.503-1.154-.468-2.158.826-1.005 1.293 4.576 1.844 9.153 3.66 13.766 5.504 1.116.467 2.158-.826 1.005-1.294zM88.278 106.572c-3.906.413-7.85.853-11.757 1.266-1.265.137-.744 1.568.521 1.43 3.907-.412 7.85-.853 11.757-1.265 1.265-.138.744-1.569-.52-1.431zM95.274 89.735c-9.264-5.173-18.566-10.346-27.83-15.52-1.042-.577-2.046.716-1.005 1.294 9.265 5.173 18.566 10.346 27.83 15.519 1.042.55 2.047-.743 1.005-1.293zM169.243 43.387a4030.377 4030.377 0 01-4.369-16.899c-.228-.861-1.541-.481-1.314.355a4038.4 4038.4 0 014.369 16.899c.203.861 1.516.507 1.314-.355z'
                fill='#FDD05B'
            />
            <path
                d='M172.324 48.429l-12.021-9.045c-.227-.152-.531 0-.531.253L160 58.994c0 .177-.152.33-.329.33l-25.759-.66c-.252 0-.404.28-.278.482l15.532 27.16c.075.152.05.33-.076.43l-12.602 9.78a.346.346 0 00.05.558l25.482 12.668c.152.076.227.253.152.405l-3.94 10.768a.27.27 0 00.05.304l3.132 3.978a.35.35 0 00.429.076l15.532-10.236a.302.302 0 01.353 0l11.314 7.651c.177.127.43.026.48-.177l3.258-10.008c.025-.076.076-.126.126-.177l16.037-10.21c.025-.026.05-.026.075-.051l9.521-10.87c.126-.151.101-.404-.076-.506l-9.798-5.548c-.177-.102-.228-.33-.076-.507l15.355-17.609a.334.334 0 00-.152-.532l-16.39-4.813c-.126-.051-.227-.178-.227-.304v-15a.315.315 0 00-.48-.278l-12.779 7.043a.333.333 0 01-.48-.253l-2.373-20.623c-.026-.254-.354-.38-.531-.203l-17.804 16.316c-.101.127-.278.127-.404.05z'
                fill='#FDD05B'
            />
            <path
                d='M220.913 81.315c-2.197.481-4.394.937-6.591 1.419-.859.177-.505 1.52.353 1.317l6.592-1.419c.884-.177.505-1.495-.354-1.317zM149.848 53.293c-2.98-3.496-5.96-6.992-8.915-10.489-.581-.684-1.541.304-.96.963 2.98 3.496 5.96 6.993 8.915 10.49.581.683 1.54-.305.96-.964z'
                fill='#FDD05B'
            />
            <path
                d='M122.623 75.893c12.4-1.57 11.945-3.75 14.9 8.209 2.955 11.958 6.743 38.865 6.743 38.865s-9.874.355-11.617-3.37c-1.743-3.724-10.026-43.704-10.026-43.704z'
                fill='#F26AAC'
            />
            <path
                d='M130.25 118.153c-.252-.253-.505-.507-.732-.76-2.5-10.058-3.308-29.795-5.101-35.926-.43-1.495-.026-2.508 1.54-2.762 9.243-1.393 7.374-2.027 10.102 8.944 2.449 9.88 5.455 29.972 6.44 36.661-3.006-1.267-6.011-2.508-9.193-3.547a149.99 149.99 0 00-3.056-2.61z'
                fill='#fff'
            />
            <path
                d='M130.149 75.792l-3.637.456c-.859.101-.884 1.47 0 1.368l3.637-.456c.858-.102.858-1.47 0-1.368z'
                fill='#5C68FF'
            />
            <path
                d='M133.483 89.194c-1.263.355-2.5.684-3.763 1.04-.859.227-.48 1.545.353 1.317 1.263-.355 2.501-.684 3.763-1.04.859-.227.505-1.545-.353-1.317zM134.189 92.615l-3.409 1.368c-.808.33-.455 1.647.353 1.317l3.41-1.368c.808-.304.454-1.647-.354-1.317z'
                fill='#8C94BF'
            />
            <path
                d='M236.596 65.505c-9.799-.684-9.572-3.192-11.163 10.515-1.591 13.706-2.954 44.363-2.954 44.363s7.753-.456 8.889-4.814c1.136-4.332 5.228-50.064 5.228-50.064z'
                fill='#000'
            />
            <path
                d='M232.883 115.24l.53-.913c1.364-11.527.556-34.279 1.894-41.373.354-1.799-.177-2.381-1.414-2.508-7.324-.76-5.909-1.647-7.374 10.945-1.313 11.325-2.475 34.203-2.854 41.829 2.273-1.697 4.546-3.344 6.97-4.788.758-1.064 1.49-2.128 2.248-3.192zM228.615 67.38v.228c0 .887 1.364.887 1.364 0v-.228c0-.887-1.364-.887-1.364 0zM234.727 67.38L232 66.924c-.859-.152-1.238 1.166-.354 1.318l2.728.456c.858.152 1.212-1.166.353-1.318z'
                fill='#5C68FF'
            />
            <path
                d='M230.939 98.214l-3.687 21.257s-12.854.278-23.259.532c-2.526-.279-5.051-.684-7.45-1.242-15.481-3.699-16.34-14.796-15.784-28.655.555-13.884-2.122-30.707-1.187-34.786.934-4.079 4.217-2.913 5.455-1.85 2.475-4.028 7.071-.81 6.667 0 0 0 1.844-1.469 4.394-.86 4.167 1.013 4.167 17.912 4.293 27.185.228 14.137 8.082 16.012 16.239 17.608 4.192.861 9.824.912 14.319.81z'
                fill='#FF1A36'
            />
            <path
                d='M193.134 54.282c.05-.887-1.313-.887-1.364 0a110.294 110.294 0 00.732 22.168c.127.862 1.49.862 1.364 0a110.22 110.22 0 01-.732-22.169zM185.86 54.079c-.025-.887-1.389-.887-1.364 0 .152 7.575.834 15.1 2.071 22.574.152.861 1.465.507 1.314-.355a152.517 152.517 0 01-2.021-22.22zM198.083 57.043c-1.111-.025-1.717-1.267-.985-2.153.556-.684-.404-1.647-.96-.963-1.49 1.773-.378 4.408 1.97 4.484.859.026.859-1.343-.025-1.368zM192.123 100.165c-1.97-5.524-.808-11.401 3.182-15.709.606-.658-.404-1.57-1.01-.912-4.293 4.662-5.607 11.097-3.46 17.051.278.837 1.591.406 1.288-.43z'
                fill='#000'
            />
            <path
                d='M217.73 119.293c-1.262-.582-2.752-.785-4.091-.278-1.01.38-1.566 1.697-.48 2.356.379.228.733.051.91-.254h.025c.278-.05.581-.076.858-.126.051 0 .076-.026.102-.051.833-.127 1.666-.279 2.5-.405.581-.076.707-1.014.176-1.242zM225.534 118.939c-.809-.735-2.273-.608-3.284-.583-1.035.025-2.449.051-3.207.836-.631.633-.606 1.545 0 2.154.379.38.884.101 1.01-.305a.754.754 0 00.707-.253h.026c.303-.025.631-.025.934-.051.051 0 .076-.025.126-.025.91-.076 1.793-.127 2.703-.203.025 0 .05-.025.075-.025.051.431.632.786 1.011.355.505-.558.505-1.343-.101-1.9z'
                fill='#F26AAC'
            />
            <path
                d='M236.368 98.442c-2.728 25.386-7.753 21.738-7.753 21.738s.682 3.902-1.137 4.333c-1.818.456-37.856 4.357-38.285 1.14-.455-3.193-1.137-5.017 2.045-5.27 1.187-.076 6.541-.228 12.754-.38 10.405-.254 23.259-.532 23.259-.532l3.687-21.257 5.657-32.709c3.637.456 2.5 7.55-.227 32.937z'
                fill='#000'
            />
            <path
                d='M178.864 106.22c-5.985 7.626-16.112 13.656-25.709 16.443-8.081 0-15.026-.025-15.026-.025l-5.379-16.19c15.178-5.32 21.416-4.788 22.35-22.042.96-17.457-1.995-21.18 1.894-22.042 3.889-.862 5.101 13.58 5.101 13.58s-1.161-22.676 3.233-23.005c3.485-.279 3.813 1.114 3.788 1.697.101-.633.556-2.356 2.652-2.483 2.576-.152 4.546 1.977 4.546 1.977s.606-2.027 3.384-1.42c1.768.406 3.535 1.673 3.409 11.2-.126 9.55 3.586 32.328-4.243 42.31z'
                fill='#5C68FF'
            />
            <path
                d='M161.439 73.968l-.833 7.068c-.101.887 1.263.862 1.364 0l.833-7.069c.126-.86-1.237-.86-1.364 0zM157.954 64.948c-.202-1.039-1.238-1.774-2.273-1.672-.859.076-.884 1.444 0 1.368.858-.076 1.364 1.09.454 1.444-.808.304-.454 1.647.354 1.318 1.01-.38 1.692-1.369 1.465-2.458zM166.263 56.688c-.405.355-1.137.28-1.44-.177-.278-.456-.05-1.19.455-1.393.808-.33.454-1.647-.354-1.318-1.313.532-2.02 2.128-1.263 3.395.758 1.267 2.551 1.368 3.586.456.632-.583-.328-1.545-.984-.963zM173.232 56.891c-1.49.026-2.273-1.47-1.49-2.71.48-.736-.707-1.445-1.187-.685-1.363 2.103.177 4.814 2.652 4.763.909-.025.909-1.393.025-1.368zM179.849 56.612c-.353.355-.984.406-1.338.026-.328-.38-.227-1.064.177-1.343.707-.507.025-1.698-.682-1.19-1.061.734-1.414 2.305-.556 3.369.834 1.039 2.475 1.039 3.384.127.606-.66-.353-1.622-.985-.989z'
                fill='#000'
            />
            <path
                d='M169.318 55.903c.127-.861-1.262-.861-1.363 0-.733 5.574-.632 13.403.025 18.977.101.861 1.465.886 1.364 0-.657-5.574-.758-13.428-.026-18.977zM176.466 54.99c.126-.86-1.263-.86-1.364 0a111.09 111.09 0 00-.733 21.486c.051.886 1.415.886 1.364 0a111.09 111.09 0 01.733-21.485z'
                fill='#fff'
            />
            <path
                d='M148.66 121.041c-1.439-.101-4.546-.354-4.445 1.85.026.785 1.111.861 1.339.253.151.102.328.178.53.127a12.61 12.61 0 013.081-.228c.177.253.505.405.834.203 0 .05 0 .101-.026.152-.404.785.783 1.469 1.187.684.935-1.824-.909-2.914-2.5-3.041zM156.994 121.041c-1.212-.101-3.839-.354-3.763 1.85.025.785.935.861 1.137.253.126.102.277.178.454.127a9.03 9.03 0 012.601-.228c.152.253.43.405.707.203 0 .05 0 .101-.025.152-.328.785.657 1.469 1.01.684.783-1.824-.757-2.914-2.121-3.041zM165.1 121.041c-1.212-.101-3.838-.354-3.762 1.85.025.785.934.861 1.136.253.126.102.278.178.455.127a9.025 9.025 0 012.601-.228c.151.253.429.405.707.203 0 .05 0 .101-.025.152-.329.785.656 1.469 1.01.684.757-1.824-.783-2.914-2.122-3.041z'
                fill='#000'
            />
            <path
                d='M180.91 123.423c-.581-.583-1.717-.861-3.334-.811-2.272.051-14.243.051-24.42.051-8.082 0-15.027-.025-15.027-.025l-5.379-16.19-10.152-30.555c-4.596.76-2.677 6.917 2.5 29.06 4.192 17.938 9.016 18.901 10.683 18.622-.051.051 1.187 5.245 3.156 6.005 2.299.886 13.511.405 21.719.532 9.016.126 18.234.506 19.421-1.064 2.096-2.711 2.146-4.612.833-5.625z'
                fill='#F26AAC'
            />
            <path
                d='M163.939 125.627c-2.197-.076-16.996.076-19.193 0-.884-.025-.884 1.343 0 1.368 2.197.076 16.996-.076 19.193 0 .884.026.884-1.342 0-1.368zM140.856 124.842c-1.616.101-2.045 2.66-.177 2.584 1.895-.101 1.642-2.66.177-2.584zM169.748 124.664c-3.41 0-2.98.431-3.081 1.875-.076 1.47 1.111.938 2.98.938 1.868 0 2.146.253 2.146-1.115 0-1.343-.252-1.698-2.045-1.698zM175.127 124.664c-2.551 0-2.223.431-2.298 1.875-.076 1.47.833.938 2.222.938s1.591.253 1.591-1.115c0-1.343-.177-1.698-1.515-1.698z'
                fill='#2E47FF'
            />
            <path
                d='M240.561 121.32c-.783 1.672-1.389 3.192-1.414 4.307-.026 1.242.404 2.99 4.141 3.674 4.723.836 10.077.861 15.481.076 2.677-.38 3.839-1.419 3.889-3.42.026-.862-.303-1.647-.959-2.281-1.339-1.342-4.773-2.888-13.486-2.584-2.955.076-5.48.152-7.652.228zm10.506 9.982c-2.778 0-5.481-.228-8.006-.684-3.536-.633-5.303-2.33-5.278-5.041.025-1.217.555-2.686 1.237-4.257-8.233.228-10.026.178-10.101.178a.666.666 0 01-.657-.71c0-.38.303-.709.707-.659.025 0 1.945.051 10.708-.202.076-.178.177-.355.252-.532 2.172-4.561 3.536-7.93 1.036-9.603-1.49-1.013-3.94-.861-5.884-.734a79.5 79.5 0 00-1.187.076c-.682.025-1.415-.026-2.223-.076-1.515-.102-3.207-.203-4.343.253-1.541.633-1.667 2.939-1.768 4.788 0 .203-.026.406-.026.583-.075 1.039-.101 2.128-.126 3.167-.05 2.686-.126 5.473-.909 8.082-1.263 4.231-5.379 4.231-8.713 4.231-.858 0-1.666 0-2.424.051a.681.681 0 01-.732-.633.682.682 0 01.631-.735c.808-.051 1.667-.051 2.525-.051 3.309 0 6.44.026 7.4-3.243.707-2.432.783-5.118.833-7.727.025-1.064.051-2.179.126-3.243 0-.178.026-.38.026-.583.101-2.001.278-5.016 2.626-5.979 1.414-.583 3.283-.456 4.95-.355.758.051 1.465.101 2.071.076.353-.025.758-.025 1.162-.051 2.171-.126 4.848-.304 6.742.963 3.612 2.458 1.44 7.094-.53 11.224 1.995-.051 4.319-.127 6.97-.228 7.4-.253 12.274.76 14.496 2.99.909.912 1.389 2.052 1.339 3.293-.127 4.003-3.839 4.561-5.051 4.738a49.019 49.019 0 01-7.879.633z'
                fill='#5C68FF'
            />
            <path
                d='M217.756 130.466c-2.349-12.059-9.647-16.772-16.693-16.265-7.046.532-8.107 4.206-9.42 16.519-.101.836 16.996 3.217 26.113-.254z'
                fill='#fff'
            />
            <path
                d='M204.674 133c-6.591 0-12.551-.963-13.385-1.622-.328-.278-.328-.608-.303-.734 1.288-12.212 2.425-16.545 10.026-17.102 6.743-.507 14.85 3.648 17.426 16.798.076.329-.127.658-.43.785-3.712 1.394-8.687 1.875-13.334 1.875zm-12.299-2.61c2.374.836 16.441 2.433 24.598-.38-2.5-11.831-9.799-15.581-15.86-15.125-6.389.481-7.45 3.572-8.738 15.505zm-.05.38v.026-.026z'
                fill='#5C68FF'
            />
            <path
                d='M204.195 114.91c0-.887-1.364-.887-1.364 0 .051 5.802.076 11.579.126 17.381 0 .886 1.364.886 1.364 0-.05-5.802-.101-11.604-.126-17.381zM200.431 114.049c-4.747 4.662-6.667 11.224-5.252 17.71.479.025.934.101 1.414.177v-.127c-1.49-6.182.303-12.364 4.823-16.823.606-.583-.353-1.545-.985-.937z'
                fill='#5C68FF'
            />
        </svg>
    );
};

import { formatDefaultFiatCurrency } from '@/ui-kit/utils';
import { Box, Typography } from '@mui/material';
import React from 'react';

type AmountRowProps = {
    title: string;
    amount: number;
    couponAmount: number;
    currency: string;
    coupon: string;
    isBold?: boolean;
};

export const AmountRow: React.FC<AmountRowProps> = ({
    title,
    amount,
    couponAmount,
    currency,
    coupon,
    isBold = false,
}) => (
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
            <Typography fontSize={18} fontWeight={isBold ? 'bold' : 'normal'}>
                {title}
            </Typography>
        </Box>
        <Box>
            <Box textAlign='right'>
                <Typography fontSize={18} fontWeight={isBold ? 'bold' : 'normal'}>
                    {formatDefaultFiatCurrency(amount, { currencyName: currency })}
                </Typography>
                <Typography
                    color='text.secondary'
                    fontSize='0.875rem'
                    fontWeight={isBold ? 'bold' : 'normal'}
                >
                    {couponAmount} {coupon}
                </Typography>
            </Box>
        </Box>
    </Box>
);

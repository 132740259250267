import { styled } from '@mui/material';
import logoImg from '../../../../../images/logo.svg';
import { FC } from 'react';
import { Link } from '@/ui-kit/components';
import { Link as MUILink } from '@material-ui/core';
import { useMatch } from 'react-router-dom';

const Img = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  height: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    height: theme.spacing(4),
  },
}));

export const Logo: FC = () => {
  const isRootLocation = useMatch('/');
  const isAuthLocation = useMatch('/auth');
  const isAuthChildLocation = useMatch('/auth/*');
  if (isRootLocation || isAuthLocation) {
    return (
      <MUILink href='/'>
        <Img alt='libersave-logo' src={logoImg} />
      </MUILink>
    );
  }
  if (isAuthChildLocation) {
    return (
      <Link href='/auth'>
        <Img alt='libersave-logo' src={logoImg} />
      </Link>
    );
  }
  return (
    <Link href='/'>
      <Img alt='libersave-logo' src={logoImg} />
    </Link>
  );
};

import { FC } from 'react';
import { Cell, Row } from './TableElements';
import { StatementStatus } from '@/transport/getAdminDataApi/types';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from '@/ui-kit/hooks';
import { OrderId } from './OrderId';

type TransactionRowProps = {
    date: string;
    merchantOrderId: string | null;
    currency: string;
    coupon: string;
    isDirectPayment: boolean;
    amount: number;
    fee: number;
    cashback: number;
    resultAmount: number;
    status: StatementStatus;
};

export const TransactionRow: FC<TransactionRowProps> = ({
    date,
    merchantOrderId,
    currency,
    coupon,
    isDirectPayment,
    amount,
    fee,
    cashback,
    resultAmount,
    status,
}) => {
    const { t } = useTranslation('statements');
    const { palette } = useTheme();

    const getResultAmountColor = (value: number) => {
        if (value > 0) {
            return palette.success.dark;
        } else if (value < 0) {
            return palette.error.dark;
        } else {
            return palette.text.primary;
        }
    };

    const getStatusColor = (status: StatementStatus) => {
        if (status === 'SUCCESS') {
            return palette.success.dark;
        } else if (status === 'CANCELED') {
            return palette.error.dark;
        } else {
            return palette.text.primary;
        }
    };

    return (
        <Row>
            <Cell
                primaryText={`${date.split('T')[0]} ${date
                    .split('T')[1]
                    .slice(0, date.split('T')[1].length - 1)}`}
            />
            <Cell
                primaryText={<Box sx={{ fontWeight: 'bold' }}>{amount}</Box>}
                secondaryText={currency}
            />
            <Cell primaryText={isDirectPayment ? t('Yes') : t('No')} />
            <Cell primaryText={fee} secondaryText={currency} />
            <Cell primaryText={cashback} secondaryText={currency} />
            <Cell
                primaryText={
                    <Box
                        sx={{
                            fontWeight: 'bold',
                            ...(status === 'SUCCESS' && {
                                color: getResultAmountColor(resultAmount),
                            }),
                        }}
                    >
                        {status === 'SUCCESS' ? resultAmount : '--'}
                    </Box>
                }
                secondaryText={currency}
            />
            <Cell primaryText={<OrderId value={merchantOrderId || '--'} />} />
            <Cell primaryText={coupon} />
            <Cell
                sx={{
                    fontWeight: 'bold',
                    color: getStatusColor(status),
                }}
                primaryText={t(status)}
            />
        </Row>
    );
};

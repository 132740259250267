export enum LoginAndResetPassErrorCode {
  WRONG_CREDENTIALS = 'LOGINERROR',
  NOT_ACTIVATED_USER = 'NOTACTIVATEDUSER',
  UNKNOWN = 'UNKNOWN',
  USERNOTFOUND = 'USERNOTFOUND',
  ROLECONFLICT = 'ROLECONFLICT',
  LOGINBLOCKED = 'LOGINBLOCKED',
  INVALIDACTIVATECODE = 'INVALIDACTIVATECODE',
  ALREADYACTIVATED = 'ALREADYACTIVATED',
  USEREXISTS = 'USEREXISTS',
  NETWORK_ERROR = 'NETWORK_ERROR',
}

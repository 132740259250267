import { Alert, Box, Button, Snackbar, Tooltip, tooltipClasses } from '@mui/material';
import React, { useState } from 'react';

interface OrderIdProps {
    value: string;
}

export const OrderId: React.FC<OrderIdProps> = ({ value }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleCopyToClipboard = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        navigator.clipboard.writeText(value);
        setOpenSnackbar(true);
    };

    const formattedValue = value.length > 7 ? `${value.slice(0, 4)}...${value.slice(-3)}` : value;

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <Tooltip
                sx={{}}
                title={value}
                PopperProps={{
                    sx: {
                        [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]: {
                            marginTop: 0,
                        },
                    },
                }}
            >
                <Button
                    onClick={handleCopyToClipboard}
                    sx={(theme) => ({
                        color: theme.palette.text.primary,
                        p: 0,
                    })}
                >
                    {formattedValue}
                </Button>
            </Tooltip>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity='success'>Order ID has been copied to the clipboard!</Alert>
            </Snackbar>
        </Box>
    );
};

import { useLazyGetMerchantsStatementsQuery } from '@/transport/getAdminDataApi';
import { MerchantsStatementType } from '@/transport/getAdminDataApi/types';
import { getDateInYYYYMMDDCurrentTZFormat } from '@/ui-kit/utils/getDateInYYYYMMDDCurrentTZFormat';
import { useCallback, useEffect, useState } from 'react';

type UseGetStatementsOptions = {
    limit?: number;
    orderIds?: string[];
    coupons?: string[];
    dateStart?: Date | null;
    dateEnd?: Date | null;
};

const DEFAULT_LIMIT = 50;

export const useGetMerchantsStatements = (options?: UseGetStatementsOptions) => {
    const { limit = DEFAULT_LIMIT, orderIds, coupons, dateStart, dateEnd } = options ?? {};

    const [triggerGetStatements, { data, isError, isLoading, isFetching }] =
        useLazyGetMerchantsStatementsQuery();
    const [totalCount, setTotalCount] = useState(0);
    const [statementDataRaw, setStatementDataRaw] = useState<MerchantsStatementType[]>([]);

    const offset = statementDataRaw.length;
    const hasNextPage = statementDataRaw.length < totalCount;

    useEffect(() => {
        if (!dateStart || !dateEnd) return;
        setStatementDataRaw([]);
        triggerGetStatements({
            limit,
            dateStart: getDateInYYYYMMDDCurrentTZFormat(dateStart),
            dateEnd: getDateInYYYYMMDDCurrentTZFormat(dateEnd),
            coupons: coupons,
            someOrderIds: orderIds,
        });
    }, [triggerGetStatements, dateStart, dateEnd, coupons, orderIds, limit]);

    const loadNextPage = useCallback(async () => {
        if (!hasNextPage || !dateStart || !dateEnd) return;
        await triggerGetStatements({
            limit,
            offset,
            someOrderIds: orderIds,
            coupons: coupons,
            dateStart: dateStart && getDateInYYYYMMDDCurrentTZFormat(dateStart),
            dateEnd: dateEnd && getDateInYYYYMMDDCurrentTZFormat(dateEnd),
        });
    }, [coupons, dateEnd, dateStart, hasNextPage, limit, offset, orderIds, triggerGetStatements]);

    // for data transform, if needed
    // const data = useMemo(() => statementDataRaw, [statementDataRaw]);

    useEffect(() => {
        if (data?.data && data?.paging.totalItems !== undefined) {
            // console.log('if data');
            setTotalCount(data.paging.totalItems);
            setStatementDataRaw((prevValue) => [...prevValue, ...data.data]);
        }
    }, [data]);

    return {
        loadNextPage,
        isLoading,
        isFetching,
        isError,
        hasNextPage,
        totalCount,
        data: statementDataRaw,
    };
};

import React, { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Button, Snackbar } from '@mui/material';

type CopyReferenceIDProps = {
    referenceId: string | undefined;
};

export const CopyReferenceID: React.FC<CopyReferenceIDProps> = ({ referenceId }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClickReferenceId = (event: React.MouseEvent<HTMLElement>) => {
        navigator.clipboard.writeText(referenceId || '');
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    return (
        <>
            <Button
                onClick={handleClickReferenceId}
                variant='text'
                disabled={!referenceId}
                sx={({ palette }) => ({
                    color: palette.text.secondary,
                    p: 0,
                    minWidth: 'unset',
                    '&:disabled': { background: 'none' },
                })}
            >
                <ContentCopyIcon fontSize='small' />
            </Button>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity='success'>Reference ID copied to clipboard!</Alert>
            </Snackbar>
        </>
    );
};

import { Coupon } from '@/transport/getAdminDataApi/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../..';

interface InitialState {
    coupons: Coupon[];
}

const initialState: InitialState = {
    coupons: [],
};

export const couponSlice = createSlice({
    name: 'coupons',
    initialState,
    reducers: {
        storeCupon: (state, action: PayloadAction<Coupon[]>) => {
            state.coupons = action.payload;
        },
    },
});

export const { storeCupon } = couponSlice.actions;

export default couponSlice.reducer;

// export const isCuponSelector = createSelector((state: RootState) => state);

import { ElevatedPaper } from '@/bo-kit/components/ElevatedPaper';
import { CouponsSelect } from '@/components/CouponsSelect';
import { useGetCouponsQuery } from '@/transport/getAdminDataApi';
import { MerchantsStatementType } from '@/transport/getAdminDataApi/types';
import { useTranslation } from '@/ui-kit/hooks';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrderIdModal from '../OrderModal';
import { CellHeader, RowHeader } from './components/TableElements';
import { TransactionRowSkeleton } from './components/TransactionRowSkeleton';
import { subMonths } from 'date-fns';
import { getDateInYYYYMMDDCurrentTZFormat } from '@/ui-kit/utils/getDateInYYYYMMDDCurrentTZFormat';
import { DateRangePicker } from '@/components/DateRangePicker';
import { TokenizedAutocomplete } from '@/components/TokenizedAutocomplete';
import { CopySummary } from './components/CopySummary';
import { formatSummary } from './utils/formatSummary';
import { ExportStatementsButton } from './components/ExportStatementsButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetMerchantsStatements } from './hooks/useGetMerchantsStatements';
import { TransactionRow } from './components/TransactionRow';
import { tryExtractOrderId } from './utils/tryExtractOrderId';

const columns = [
    'Transaction date',
    'Amount',
    'Direct payment',
    'Fee',
    'Cashback',
    'Result amount',
    'Order ID',
    'Coupon',
    'Status',
];

const today = new Date();
const monthAgo = subMonths(new Date(), 1);

export const TransactionTable: FC = () => {
    const { t } = useTranslation('statements');

    const [searchParams, setSearchParams] = useSearchParams();

    const [dateStart, setDateStart] = useState(new Date(searchParams.get('dateStart') || monthAgo));
    const [dateEnd, setDateEnd] = useState(new Date(searchParams.get('dateEnd') || today));
    const [selectedCoupons, setSelectedCoupons] = useState<string[]>(
        searchParams.getAll('coupons[]') || []
    );
    const [orderIds, setOrderIds] = useState<string[]>(searchParams.getAll('someOrderIds[]') || []);

    useEffect(() => {
        const searchParamsNew = new URLSearchParams({
            dateStart: getDateInYYYYMMDDCurrentTZFormat(dateStart),
            dateEnd: getDateInYYYYMMDDCurrentTZFormat(dateEnd),
        });

        selectedCoupons.forEach((coupon) => searchParamsNew.append('coupons[]', coupon));
        orderIds.forEach((orderId) => searchParamsNew.append('someOrderIds[]', orderId));

        setSearchParams(searchParamsNew);
    }, [dateStart, dateEnd, selectedCoupons, orderIds, setSearchParams]);

    const handleDateChange = ({
        dateStart,
        dateEnd,
    }: {
        dateStart: Date | null;
        dateEnd: Date | null;
    }) => {
        setDateStart(dateStart || monthAgo);
        setDateEnd(dateEnd || today);
    };

    const handleSelectedCouponsChange = (coupons: string[]) => {
        setSelectedCoupons(coupons);
    };

    const handleOrderIdChange = (orderIds: string[]) => {
        setOrderIds(orderIds);
    };

    const [openOrderId, setOpenOrderId] = useState<null | string>(null);

    const handleOpenModal = (id: string) => {
        setOpenOrderId(id);
    };
    const handleCloseModal = () => {
        setOpenOrderId(null);
    };

    const {
        data: merchantsStatementsData,
        isError,
        isLoading,
        loadNextPage,
        hasNextPage,
    } = useGetMerchantsStatements({
        dateStart: dateStart,
        dateEnd: dateEnd,
        coupons: selectedCoupons,
        orderIds: orderIds,
    });

    const handleClickSummary = () => {
        if (!merchantsStatementsData) return;
        navigator.clipboard.writeText(formatSummary(orderIds, merchantsStatementsData));
    };

    const { data: couponData } = useGetCouponsQuery();

    const couponsAbbr = couponData?.data.map((coupon) => coupon.abbr) || [];

    return (
        <Box
            sx={{
                '@media (min-width: 1500px)': {
                    marginRight: '-180px',
                },
                // TEMP HACK WITH MARGIN TO EXPAND TABLE
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: 0,
                    position: 'relative',
                }}
            >
                <ExportStatementsButton
                    sx={{ position: 'absolute', bottom: 0 }}
                    dateStart={getDateInYYYYMMDDCurrentTZFormat(dateStart)}
                    dateEnd={getDateInYYYYMMDDCurrentTZFormat(dateEnd)}
                    coupons={selectedCoupons}
                />
            </Box>

            <ElevatedPaper
                sx={({ spacing }) => ({
                    py: spacing(3),
                    px: spacing(5),
                    // minWidth: '380px',
                    // maxWidth: '1400px',
                    width: '100%',
                    margin: '0 auto',
                    mb: spacing(4),
                })}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1.2fr 1.2fr 2fr 2fr',
                        columnGap: '10px',
                        rowGap: '10px',
                        maxWidth: '1400px',
                        width: '100%',
                        margin: '0 auto',
                        '@media (max-width: 768px)': {
                            gridTemplateColumns: '1fr',
                        },
                    }}
                >
                    <DateRangePicker
                        date={{ dateStart, dateEnd }}
                        onDateChange={handleDateChange}
                    />
                    <TokenizedAutocomplete
                        value={orderIds}
                        onChange={handleOrderIdChange}
                        placeholder='Filter by order ID (or payment URL)'
                        label={t('Order ID')}
                        formatTagBeforeAdd={tryExtractOrderId}
                    />
                    <CouponsSelect
                        coupons={couponsAbbr}
                        selectedCoupons={selectedCoupons}
                        onChange={handleSelectedCouponsChange}
                        limitTags={2}
                    />
                </Box>
            </ElevatedPaper>
            <Box
                sx={{
                    // minWidth: '380px',
                    // maxWidth: '1400px',
                    width: '100%',
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        mt: spacing(4),
                        py: spacing(3),
                        px: spacing(5),
                        width: 'fit-content',
                        minWidth: '100%',
                        margin: '0 auto',
                        mb: spacing(1),
                    })}
                >
                    <div>
                        <RowHeader
                            sx={({ spacing }) => ({
                                mt: spacing(1),
                                px: spacing(5),
                                py: spacing(2.5),
                            })}
                        >
                            {columns.map((columnName) => (
                                <CellHeader key={columnName}>
                                    {t(columnName)}
                                    {columnName === 'Status' && (
                                        <CopySummary onClick={handleClickSummary} />
                                    )}
                                </CellHeader>
                            ))}
                        </RowHeader>
                    </div>
                </ElevatedPaper>
                <ElevatedPaper
                    sx={({ spacing }) => ({
                        mt: spacing(4),
                        py: spacing(3),
                        px: spacing(5),
                        // minWidth: '380px',
                        // maxWidth: '1400px',
                        width: 'fit-content',
                        minWidth: '100%',
                        margin: '0 auto',
                    })}
                >
                    {isLoading &&
                        Array.from({ length: 8 }, (_, index) => index).map((_, index) => (
                            <TransactionRowSkeleton key={index} />
                        ))}

                    {!isError && !isLoading && (
                        <InfiniteScroll
                            dataLength={merchantsStatementsData.length}
                            next={loadNextPage}
                            hasMore={hasNextPage}
                            loader={<TransactionRowSkeleton />}
                        >
                            {merchantsStatementsData?.map((item: MerchantsStatementType, index) => (
                                <div
                                    key={item.id + item.datetime + index}
                                    onClick={() => {
                                        handleOpenModal(item.id);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TransactionRow
                                        date={item.datetime}
                                        merchantOrderId={item.merchantOrderId}
                                        currency={item.currency}
                                        coupon={item.coupon}
                                        isDirectPayment={item.isDirectPayment}
                                        amount={item.amount}
                                        fee={item.fee}
                                        cashback={item.cashback}
                                        resultAmount={item.resultAmount}
                                        status={item.status}
                                    />
                                </div>
                            ))}
                        </InfiniteScroll>
                    )}

                    <OrderIdModal onClose={handleCloseModal} orderId={openOrderId} />
                </ElevatedPaper>
            </Box>
        </Box>
    );
};

import { SideMenuContextProvider, SideMenuItemType } from '@/bo-kit/components';
import { I18nHookContextProvider } from '@/ui-kit/hooks/useTranslation';
import { theme } from '@/ui-kit/theme';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export const Providers: FC<PropsWithChildren> = ({ children }) => {
    const hideItems = [
        SideMenuItemType.APIKEYS,
        SideMenuItemType.PAYMENTSETTINGS,
        SideMenuItemType.REWARDS,
        SideMenuItemType.STATEMENTS,
        SideMenuItemType.CATALOG,
        SideMenuItemType.PAYMENTS,
        SideMenuItemType.SETTINGS,
    ];

    return (
        <StyledEngineProvider>
            <ThemeProvider theme={theme}>
                <I18nHookContextProvider hook={useTranslation}>
                    <SideMenuContextProvider
                        value={{
                            hideItems,
                        }}
                    >
                        {children}
                    </SideMenuContextProvider>
                </I18nHookContextProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

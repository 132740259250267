import React, { useState } from 'react';
import { Alert, Button, Snackbar } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

type CopySummaryProps = {
    onClick: () => void;
};

export const CopySummary: React.FC<CopySummaryProps> = ({ onClick }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClickCopy = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onClick();
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Button
                onClick={handleClickCopy}
                variant='text'
                sx={({ palette }) => ({
                    color: palette.text.secondary,
                    // height: 0,
                    position: 'absolute',
                    py: 0,
                    px: 1,
                    padding: 0,
                    margin: 0,
                    // minWidth: 0,
                })}
            >
                <FileCopyIcon fontSize={'small'} />
            </Button>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity='success'>
                    Summary of current orders status copied to clipboard!
                </Alert>
            </Snackbar>
        </>
    );
};

import { IFaceLoader, NonAuthorizedLayout, ResponsiveLazyRoute } from '@/bo-kit/components';
import NotFoundPage from '@/bo-kit/components/NotFoundPage';
import AuthorizedLayout from '@/components/AuthorizedLayout';
import CheckAuthComponent from '@/components/CheckAuthComponent/CheckAuthComponent';
import AuthPage from '@/pages/Auth';
import LogoutPage from '@/pages/Logout';
import DesktopMainPage from '@/pages/Main/DesktopMainPage';
import MobileMainPage from '@/pages/Main/MobileMainPage';
import DesktopSearchTransactionPage from '@/pages/SearchTransactions/DesktopSearchTransactionPage';
import MobileSearchTransactionPage from '@/pages/SearchTransactions/MobileSearchTransactionPage';
import DesktopTransferVolumePage from '@/pages/ShowTransferVolume/DesktopShowTransferVolumePage';
import MobileTransferVolumePage from '@/pages/ShowTransferVolume/MobileShowTransferVolumePage';
import { FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes as RoutesBase } from 'react-router-dom';

const RoutesInner: FC = () => {
    const { t } = useTranslation('bo-kit__common');

    return (
        <RoutesBase>
            <Route
                path='/'
                element={
                    <CheckAuthComponent>
                        <AuthorizedLayout useDummyLayoutForDesktop={false} />
                    </CheckAuthComponent>
                }
            >
                <Route
                    index
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopMainPage />}
                            mobile={<MobileMainPage />}
                            title={t('Home')}
                        />
                    }
                />
                <Route
                    path='show-transfer-volume'
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopTransferVolumePage />}
                            mobile={<MobileTransferVolumePage />}
                            title='Daily transfer volume'
                        />
                    }
                />

                <Route
                    path='search-transactions'
                    element={
                        <ResponsiveLazyRoute
                            implementation='js'
                            desktop={<DesktopSearchTransactionPage />}
                            mobile={<MobileSearchTransactionPage />}
                            title='Transactions'
                        />
                    }
                />
            </Route>
            <Route path='auth' element={<NonAuthorizedLayout />}>
                <Route index element={<AuthPage />} />
            </Route>
            <Route
                path='logout'
                element={
                    <Suspense fallback={<IFaceLoader />}>
                        <LogoutPage />
                    </Suspense>
                }
            />
            <Route
                path='*'
                element={
                    <Suspense fallback={<IFaceLoader />}>
                        <NotFoundPage />
                    </Suspense>
                }
            />
        </RoutesBase>
    );
};

export const Routes: FC = () => (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <RoutesInner />
    </BrowserRouter>
);

import React, { PropsWithChildren } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from '@/ui-kit/hooks';
import { Language } from '@/ui-kit/types';
import { getDateFnsLocale } from '@/ui-kit/utils/i18nFormatters/getDateFnsLocale';

export const DatePickerLocalizationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language as Language;
    const currentLocale = getDateFnsLocale(currentLang);

    return (
        <LocalizationProvider
            localeText={{ clearButtonLabel: t('Clear') }}
            adapterLocale={currentLocale}
            dateAdapter={AdapterDateFns}
        >
            {children}
        </LocalizationProvider>
    );
};

import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Link as MUILink,
  Stack,
  styled,
  Tab,
  TextField,
  TypographyVariant,
} from '@mui/material';
import liberBlack from '@/ui-kit/theme/colors/liberBlack';
import { FormError as FormErrorBase, Link, TypographyBody3 } from '@/ui-kit/components';
import React, { FC, useCallback, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import liberWhite from '@/ui-kit/theme/colors/liberWhite';
import TabList from '@mui/lab/TabList';
import { UserType } from '@/bo-kit/types';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useIsDesktop } from '@/ui-kit/hooks/useIsDesktop';
import { useReturnUrl } from '@/bo-kit/hooks/useReturnUrl';
import { SignUpRequest } from '@/bo-kit/transport/types/SignUpRequest';
import { isAuthFrontend } from '@/ui-kit/utils';

const FormError = styled(FormErrorBase)(({ theme: { spacing } }) => ({ marginBottom: spacing(2) }));

const UserTypeTabs: FC<{ formik: FormikProps<SignUpRequest> }> = ({ formik }) => {
  const { t } = useTranslation('bo-kit__signup');
  const onTabSelect = useCallback(
    (e: unknown, newValue: string) => {
      formik.setFieldValue('userType', Number(newValue) as UserType, false);
    },
    [formik]
  );
  return (
    <TabContext value={String(formik.values.userType)}>
      <Box
        sx={({ spacing }) => ({
          borderBottom: `1px solid ${liberWhite[200]}`,
          width: '100%',
          mb: spacing(3),
        })}
      >
        <TabList
          name='userType'
          onChange={onTabSelect}
          textColor='inherit'
          sx={({ typography, spacing }) => ({
            fontWeight: typography.fontWeightLight,
            '& .Mui-selected': {
              fontWeight: typography.fontWeightMedium,
            },
            '& .MuiTabs-indicator': {
              height: spacing(0.5),
              borderRadius: spacing(0.5),
            },
          })}
        >
          <Tab
            label={t('Customer')}
            value={String(UserType.Customer)}
            sx={{ width: '50%', textTransform: 'none' }}
          />
          <Tab
            label={t('Merchant')}
            value={String(UserType.Merchant)}
            sx={{ width: '50%', textTransform: 'none' }}
          />
        </TabList>
      </Box>
    </TabContext>
  );
};

interface State {
  showPassword: boolean;
  showConfirmPassword: boolean;
}

export const SignUpPageFormInner: FC<{
  formik: FormikProps<SignUpRequest>;
  isLoading: boolean;
  isUserExistError: boolean;
  clearState: () => void;
  isError: boolean;
  errorMessage?: string;
}> = ({ formik, isLoading, isUserExistError, clearState, isError, errorMessage }) => {
  const { t } = useTranslation('bo-kit__signup');
  const isDesktop = useIsDesktop();
  const onUsernameChange = useCallback<
    (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  >(
    (e) => {
      if (!isUserExistError) {
        clearState();
      }
      formik.handleChange(e);
    },
    [clearState, formik, isUserExistError]
  );
  const [state, setState] = useState<State>({
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleClickShowPassword = useCallback(() => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  }, [state]);
  const handleClickShowConfirmPassword = useCallback(() => {
    setState({
      ...state,
      showConfirmPassword: !state.showConfirmPassword,
    });
  }, [state]);
  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);
  const [returnUrl] = useReturnUrl();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ maxWidth: isDesktop ? 300 : 'unset' }}>
        <UserTypeTabs formik={formik} />
        <Stack spacing={1}>
          <div>
            <TextField
              sx={{ pb: 3 }}
              fullWidth
              type='text'
              label={t('E-mail')}
              name='username'
              onChange={onUsernameChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              error={(formik.touched.username && !!formik.errors.username) || isUserExistError}
              helperText={
                (formik.touched.username && formik.errors.username) ||
                (isUserExistError && t('Already exist, try another email'))
              }
              placeholder={t('Enter your e-mail')}
              autoComplete='username email'
            />
            <TextField
              sx={{ pb: 3 }}
              fullWidth
              type={state.showPassword ? 'text' : 'password'}
              label={t('Password')}
              name='password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && !!formik.errors.password}
              placeholder={t('Enter password')}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete='new-password'
            />
            <TextField
              fullWidth
              sx={{ pb: 2 }}
              type={state.showConfirmPassword ? 'text' : 'password'}
              label={t('Confirm password')}
              name='confirmPassword'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
              placeholder={t('Confirm password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              autoComplete='new-password'
            />
          </div>
          {isError && errorMessage && !isUserExistError && <FormError text={errorMessage} />}
          <FormControl
            sx={({ spacing }) => ({ pb: spacing(3) })}
            required
            error={formik.touched.password && !!formik.errors.termsAndCondition}
            variant='standard'
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                mr: 0,
              }}
            >
              <Checkbox
                sx={({ spacing }) => ({
                  '& .MuiSvgIcon-root': {
                    fontSize: spacing(5),
                  },
                  p: 0,
                })}
                name='termsAndCondition'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.termsAndCondition}
                color='primary'
              />

              <MUILink
                href='https://libersave.com/d/docs/legal/'
                target={'_blank'}
                underline='always'
                fontSize={12}
                sx={({ spacing }) => ({
                  ml: spacing(1.5),
                  color: liberBlack[100],
                  textDecorationColor: liberBlack[100],
                })}
              >
                {t('Terms and Conditions the Concept to Personal Data Processing')}
              </MUILink>
            </Box>
            {formik.touched.termsAndCondition && (
              <FormHelperText
                error={formik.touched.termsAndCondition && !!formik.errors.termsAndCondition}
              >
                {formik.errors.termsAndCondition}
              </FormHelperText>
            )}
          </FormControl>
          <Stack spacing={1}>
            <Button
              type='submit'
              variant='contained'
              fullWidth
              color='primary'
              disabled={isLoading}
            >
              {t('Sign Up')}
            </Button>
            <TypographyBody3 color='textSecondary'>
              {t('Already have an account?')}
              <Link
                variant={'link4' as TypographyVariant}
                color={liberBlack[200]}
                href={isAuthFrontend() ? '/' : '/auth'}
                state={{ returnUrl }}
              >
                {t('Log In')}
              </Link>
            </TypographyBody3>
          </Stack>
        </Stack>
      </Box>
    </form>
  );
};

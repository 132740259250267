import { FC } from 'react';
import { Typography, Button, styled, Stack } from '@mui/material';
import { TFunction } from 'react-i18next';

type QRScanErrorProps = {
    onRetry: () => void;
    t: TFunction;
};

const QRScanErrorContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#c3c3c6',
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
}));

const QRScanErrorModal = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
}));

export const QRScanError: FC<QRScanErrorProps> = ({ onRetry, t }) => {
    return (
        <QRScanErrorContainer>
            <QRScanErrorModal>
                <Stack spacing={3}>
                    <Typography variant='h3'>{t('qr_scan.error_title')}</Typography>
                    <Typography variant='body1' color='secondary'>
                        {t('qr_scan.error_description')}
                    </Typography>
                    <Button onClick={onRetry} variant='contained' fullWidth>
                        {t('qr_scan.retry_button')}
                    </Button>
                </Stack>
            </QRScanErrorModal>
        </QRScanErrorContainer>
    );
};

import * as dateLocales from 'date-fns/locale'; // import all locales we need
import { Language } from '@/ui-kit/types';

const dateLocalesMap = {
    en: dateLocales.enUS,
    de: dateLocales.de,
    ru: dateLocales.ru,
};

export const getDateFnsLocale = (language: Language): Locale =>
    dateLocalesMap[language] ?? dateLocales.enUS;

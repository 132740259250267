import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    CouponResponseRaw,
    DataByOrderIdRequest,
    DataByOrderIdResponse,
    GetExportedStatementsByCouponsRequest,
    GetExportedStatementsByCouponsResponse,
    MerchantsStatementsRequest,
    MerchantsStatementsResponse,
    TransferVolumeRequest,
    TransferVolumeResponse,
} from './types';

const baseUrl = process.env.REACT_APP_STAT_BASE_URL;

const reducerPath = 'gatewayApiAdminData' as const;

export const gatewayApiAdminData = createApi({
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    reducerPath,
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (build) => ({
        getCoupons: build.query<CouponResponseRaw, void>({
            query: () => ({
                url: '/api/directory/coupons',
                method: 'GET',
                credentials: 'include',
            }),
        }),
        getTransferVolume: build.query<TransferVolumeResponse, TransferVolumeRequest>({
            query: ({ dateStart, dateEnd, coupons }) => {
                const queryParams = new URLSearchParams({ dateStart, dateEnd });
                coupons?.forEach((coupon) => queryParams.append('coupons[]', coupon));
                const queryParamsString = queryParams.toString();

                return {
                    url: '/api/stat/daily-transfers-volume?' + queryParamsString,
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
        getMerchantsStatements: build.query<
            MerchantsStatementsResponse,
            MerchantsStatementsRequest
        >({
            query: ({ dateStart, dateEnd, coupons, someOrderIds, limit, offset }) => {
                const queryParams = new URLSearchParams({
                    ...(typeof limit === 'number' && { limit: limit.toString() }),
                    ...(typeof offset === 'number' && { offset: offset.toString() }),
                    ...(typeof dateStart === 'string' && { dateStart }),
                    ...(typeof dateEnd === 'string' && { dateEnd }),
                });
                coupons?.forEach((coupon) => queryParams.append('coupons[]', coupon));
                someOrderIds?.forEach((orderId) => queryParams.append('someOrderIds[]', orderId));
                const queryParamsString = queryParams.toString();

                return {
                    url: '/api/stat/merchants-statements?' + queryParamsString,
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
        getDataByOrderId: build.query<DataByOrderIdResponse, DataByOrderIdRequest>({
            query: (arg) => ({
                url: '/api/orders/' + arg,
                method: 'GET',
                credentials: 'include',
            }),
        }),
        getExportedStatementsByCoupons: build.mutation<
            GetExportedStatementsByCouponsResponse,
            GetExportedStatementsByCouponsRequest
        >({
            query: ({ coupons, dateStart, dateEnd }) => {
                return {
                    url: '/api/stat/export-statement',
                    method: 'POST',
                    credentials: 'include',
                    body: {
                        coupons,
                        dateStart,
                        dateEnd,
                        deliveryType: 'download',
                    },
                };
            },
        }),
    }),
});

export const {
    useGetCouponsQuery,
    useGetTransferVolumeQuery,
    useGetMerchantsStatementsQuery,
    useLazyGetMerchantsStatementsQuery,
    useGetDataByOrderIdQuery,
    useGetExportedStatementsByCouponsMutation,
} = gatewayApiAdminData;

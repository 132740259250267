import { MerchantsStatementType, StatementStatus } from '@/transport/getAdminDataApi/types';

type OrderLike = { merchantOrderId: string; status: StatementStatus | null };

export const formatSummary = (orderIds: string[], statements: MerchantsStatementType[]) => {
    const notFoundOrders: OrderLike[] = [];

    if (orderIds.length > 0) {
        const notFoundOrderIds = orderIds.filter(
            (orderId) => !statements.find((statement) => statement.merchantOrderId === orderId)
        );
        notFoundOrderIds.forEach((orderId) => {
            notFoundOrders.push({
                merchantOrderId: orderId,
                status: null,
            });
        });
    }

    const summaryText = [...statements, ...notFoundOrders]
        .map((statement) => formatStatementStatus(statement.merchantOrderId, statement.status))
        .join('\n');

    return summaryText || 'Список платежей пуст';
};

const formatStatementStatus = (id: string | null, status: StatementStatus | null) => {
    switch (status) {
        case 'SUCCESS':
            return `${id} - оплачен`;
        case 'CANCELED':
            return `${id} - отменен`;
        case 'PENDING':
            return `${id} - нет подтверждения об оплате, на счёт не поступал`;
        case null:
            return `${id} - не было переходов в платежную систему`;
    }
};

import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// import SearchBar from './components/SearchBar';

import { TransactionTable } from '../components/TransactionTable';

const MobileSearchTransactionPage: FC = () => {
    const { t } = useTranslation('statements');

    return (
        <>
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <Typography variant='h1' fontWeight='bold' sx={{ mb: 1.25 }}>
                        {t('Statements')}
                    </Typography>
                </Box>
            </Stack>
            <TransactionTable />
        </>
    );
};

export default MobileSearchTransactionPage;

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, Paper, Radio, styled, Button, SxProps, ButtonProps, Popover } from '@mui/material';
import { ThemeType } from '../../../theme';
import _ from 'lodash';
import { Language } from '../../../types';

type LanguageOption = {
    icon: React.ReactNode;
    code: string;
    name: string;
};

type Align = 'left' | 'right';
type VerticalAlign = 'top' | 'bottom';
type Variant = 'short' | 'full';

const Dropdown = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1, 0),
}));

const RadioContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(0, 2),
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
}));

const LanguageButton = styled(
    (props: Omit<ButtonProps, 'variant'> & { variant: 'short' | 'full' }) => (
        <Button variant='text' {..._.omit(props, ['variant'])} />
    )
)(({ theme, variant }) => ({
    textTransform: variant === 'short' ? 'uppercase' : 'capitalize',
    color: theme.palette.common.black,
}));

const LanguageName = styled(Box)(({ theme }) => ({
    fontSize: 14,
    flexGrow: 1,
    marginRight: theme.spacing(5),
}));

const LanguageRadio = styled(Radio)(({ theme }) => ({
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
}));

type LanguagePickerProps = {
    languages: LanguageOption[];
    currentCode: Language;
    onChange: (language: string) => void;
    dropdownAlign?: Align;
    dropdownVerticalAlign?: VerticalAlign;
    variant?: Variant;
    sx?: SxProps<ThemeType>;
};

export const LanguagePicker: FC<LanguagePickerProps> = ({
    dropdownAlign = 'right',
    dropdownVerticalAlign = 'bottom',
    variant = 'short',
    languages,
    currentCode,
    sx,
    onChange,
}) => {
    const [opened, setOpened] = useState(false);

    const handleClickOutside = useCallback(() => {
        setOpened(false);
    }, []);

    const currentLanguage = useMemo(() => {
        return languages.find((lang) => lang.code === currentCode);
    }, [languages, currentCode]);

    const handleToggleDropdown = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpened((opened) => !opened);
    }, []);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    if (!currentLanguage) {
        return null;
    }

    return (
        <>
            <LanguageButton
                variant={variant}
                onClick={handleToggleDropdown}
                startIcon={currentLanguage.icon}
                sx={sx}
            >
                {variant === 'short' ? currentLanguage.code : currentLanguage.name}
            </LanguageButton>
            <Popover
                open={opened}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: dropdownVerticalAlign,
                    horizontal: dropdownAlign,
                }}
                onClose={handleClickOutside}
            >
                <Dropdown
                    sx={{
                        left: dropdownAlign === 'left' ? 0 : 'initial',
                        right: dropdownAlign === 'left' ? 'initial' : 0,
                        bottom: dropdownVerticalAlign === 'top' ? '100%' : 'initial',
                    }}
                >
                    {languages.map((language) => (
                        <RadioContainer key={language.code} onClick={() => onChange(language.code)}>
                            <LanguageRadio
                                onChange={() => onChange(language.code)}
                                checked={currentCode === language.code}
                            />
                            <LanguageName
                                component='span'
                                sx={({ typography }) => ({
                                    fontWeight:
                                        currentCode === language.code
                                            ? typography.fontWeightRegular
                                            : typography.fontWeightLight,
                                })}
                            >
                                {language.name}
                            </LanguageName>
                            {language.icon}
                        </RadioContainer>
                    ))}
                </Dropdown>
            </Popover>
        </>
    );
};

import React from 'react';

import { DataByOrderIdResponse } from '@/transport/getAdminDataApi/types';

import { OrderModalPaper } from '../OrderModalPaper';
import { Box, Chip, Divider, Typography, Stack } from '@mui/material';
import { CreditCard, SwapHoriz, AccountBalance } from '@mui/icons-material';
import { CopyReferenceID } from './components/CopyReferenceID';
import { formatInstitutionData } from './utils';
import { InfoCard, InfoContent, InfoTitle } from './components/InfoItem';
import { StatusChip } from './components/StatusChip';
import { AmountRow } from './components/AmountRow';

interface OrderInfoProps {
    data: DataByOrderIdResponse;
}

export const OrderInfo: React.FC<OrderInfoProps> = ({ data }) => {
    const formattedDate = data.datetime.replace('T', ', ').split('.').slice(0, 1);

    const orderId = data.merchantOrderId || 'N/A';
    const referenceId = data.openbanking?.referenceId;

    const bankInfo =
        formatInstitutionData(
            data.openbanking?.institutionId,
            data.openbanking?.institutionCountryCode
        ) || 'N/A';

    const isFundsCollected = data.openbanking?.isFundsCollected;

    return (
        <OrderModalPaper>
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={4}>
                <Typography variant='h2' fontWeight='bold'>
                    Order Details
                </Typography>
                <Box>
                    {isFundsCollected !== undefined && (
                        <Chip
                            label={isFundsCollected ? 'Collected' : 'Not collected'}
                            sx={({ palette }) => ({
                                mr: 1,
                                color: 'white',
                                backgroundColor: isFundsCollected
                                    ? palette.success.light
                                    : palette.text.secondary,
                            })}
                        />
                    )}
                    <StatusChip status={data.status} />
                </Box>
            </Box>

            <Box sx={{ mb: 4 }}>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr', md: '4fr 2.5fr 2.2fr' },
                        mb: 3,
                        gap: 3,
                        alignItems: 'start',
                    }}
                >
                    <InfoCard>
                        <InfoTitle>Order ID</InfoTitle>
                        <InfoContent>{orderId}</InfoContent>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>

                    <InfoCard>
                        <InfoTitle>Reference ID</InfoTitle>
                        <InfoContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>{referenceId || 'N/A'}</Box>
                            {referenceId && <CopyReferenceID referenceId={referenceId} />}
                        </InfoContent>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>

                    <InfoCard>
                        <InfoTitle>Date & Time</InfoTitle>
                        <InfoContent>{formattedDate}</InfoContent>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr', md: '1fr 2fr 2fr' },
                        mb: 3,
                        gap: 3,
                        alignItems: 'start',
                    }}
                >
                    <InfoCard>
                        <InfoTitle>Payment Method</InfoTitle>
                        <Box display='flex' alignItems='center'>
                            <CreditCard sx={{ mr: 1 }} />
                            <InfoContent>{data.paymentMethod}</InfoContent>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>

                    <InfoCard>
                        <InfoTitle>Bank Info</InfoTitle>
                        <Box display='flex' alignItems='center'>
                            <AccountBalance sx={{ mr: 1 }} />
                            <InfoContent>{bankInfo}</InfoContent>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>

                    <InfoCard>
                        <InfoTitle>Purchase Information</InfoTitle>
                        <InfoContent display='flex' alignItems='center' flexWrap='wrap'>
                            <Box display='flex' alignItems='center'>
                                <Typography fontSize={18} fontWeight='bold'>
                                    Currency
                                </Typography>
                                <Chip
                                    label={data.currency}
                                    sx={{ color: 'white', fontWeight: 'bold', mx: 1 }}
                                    color='primary'
                                    size='small'
                                />
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <SwapHoriz sx={{ mr: 1 }} />
                                <Typography fontSize={18} fontWeight='bold'>
                                    Coupon
                                </Typography>
                                <Chip
                                    label={data.coupon}
                                    sx={{ color: 'white', fontWeight: 'bold', mx: 1 }}
                                    color='secondary'
                                    size='small'
                                />
                            </Box>
                        </InfoContent>
                        <Divider sx={{ my: 1 }} />
                    </InfoCard>
                </Box>
            </Box>
            <Box>
                <InfoTitle>Transaction Summary</InfoTitle>

                <Stack direction='column' gap={1}>
                    <AmountRow
                        title='Amount'
                        amount={data.amount}
                        couponAmount={data.amountCoupons}
                        currency={data.currency}
                        coupon={data.coupon}
                    />
                    <AmountRow
                        title='Fee'
                        amount={data.fee}
                        couponAmount={data.feeCoupons}
                        currency={data.currency}
                        coupon={data.coupon}
                    />
                    <AmountRow
                        title='Cashback'
                        amount={data.cashback}
                        couponAmount={data.cashbackCoupons}
                        currency={data.currency}
                        coupon={data.coupon}
                    />
                    <Divider />
                    <AmountRow
                        title='Result Amount'
                        amount={data.resultAmount}
                        couponAmount={data.resultAmountCoupons}
                        currency={data.currency}
                        coupon={data.coupon}
                        isBold
                    />
                </Stack>
            </Box>
        </OrderModalPaper>
    );
};

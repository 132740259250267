import { FC, ReactNode, useMemo } from 'react';
import { Helmet } from 'react-helmet';

export const HtmlPageTitle: FC<{
  title: ReactNode;
}> = ({ title: _title }) => {
  const title = useMemo(() => (_title ? `LiberSave - ${_title}` : null), [_title]);
  return title ? (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  ) : null;
};

import { PageTitle } from '@/ui-kit/components';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionTable } from '../components/TransactionTable';
// import SearchBar from './components/SearchBar';

// import { TransactionTable } from '../components/TransactionTable';

const DesktopSearchTransactionPage: FC = () => {
    const { t } = useTranslation('statements');

    return (
        <Box
            sx={({ spacing }) => ({
                pb: spacing(5),
            })}
        >
            <Stack direction='row' justifyContent='space-between'>
                <Box>
                    <PageTitle>{t('Statements')}</PageTitle>
                </Box>
            </Stack>
            <TransactionTable />
        </Box>
    );
};

export default DesktopSearchTransactionPage;

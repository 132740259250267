import { FC } from 'react';
import * as React from 'react';
import { Box, styled } from '@mui/material';
import _ from 'lodash';
import liberWhite from '../../theme/colors/liberWhite';
import liberRed from '../../theme/colors/liberRed';
import liberBlack from '../../theme/colors/liberBlack';
import { StartIcon } from '../../icons';

type StarsRatingProps = {
    rating: number;
};

const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const RatingLabel = styled(Box)(({ theme }) => ({
    color: liberBlack[100],
    fontWeight: '500',
    fontSize: 16,
    marginLeft: theme.spacing(1),
}));

const StarsContainer = styled(Box)({
    position: 'relative',
    display: 'inline-block',
});

const FilledStars = styled(Box)({
    position: 'absolute',
    top: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
});

export const StarsRating: FC<StarsRatingProps> = ({ rating }) => {
    return (
        <Container>
            <StarsContainer>
                <Box>
                    {_.range(0, 5).map((a, key) => (
                        <StartIcon
                            key={key}
                            sx={{
                                width: 14,
                                height: 14,
                                fill: liberWhite[100],
                            }}
                        />
                    ))}
                </Box>

                <FilledStars
                    style={{
                        width: `${rating * 20}%`,
                    }}
                >
                    {_.range(0, 5).map((a, key) => (
                        <StartIcon
                            key={key}
                            sx={{
                                width: 14,
                                height: 14,
                                fill: liberRed[300],
                            }}
                        />
                    ))}
                </FilledStars>
            </StarsContainer>
            <RatingLabel component='span'>{rating}</RatingLabel>
        </Container>
    );
};

import { Language } from '../types';
import { removeTrailingSlash } from './removeTrailingSlash';
import { LiberSaveFrontend } from '@/bo-kit/types';
import { getCurrentFrontend } from './getCurrentFrontend';

export const getCCUrl = () => process.env.REACT_APP_CC_URL || 'https://libersave.com/cc';

export const getMCUrl = () =>
    removeTrailingSlash(process.env.REACT_APP_MC_URL ?? 'https://libersave.com/mc');

export const getACUrl = () => {
    if (getCurrentFrontend() === LiberSaveFrontend.AUTH) {
        return new URL(`${document.location.origin}${process.env.PUBLIC_URL}`).toString();
    }
    return removeTrailingSlash(
        process.env.REACT_APP_REDIRECT_AUTH_URL ?? 'https://libersave.com/auth'
    );
};

export const getRootUrl = () => {
    const frontend = getCurrentFrontend();
    switch (frontend) {
        case LiberSaveFrontend.CC:
            return getCCUrl();
        case LiberSaveFrontend.MC:
            return getMCUrl();
        case LiberSaveFrontend.AUTH:
            return getACUrl();
        default:
            return '';
    }
};

export const getReturnUrl = (): string | null => {
    const { url, replaced } = [
        { from: getMCUrl(), to: '{MC}' },
        { from: getCCUrl(), to: '{CC}' },
    ].reduce(
        ({ url, replaced }, { from, to }) => {
            if (removeTrailingSlash(window.location.href) === from) {
                /**
                 * if current location full href is eq to some UI (CC or MC)
                 * base path (https://libersave.com/mc or https://libersave.com/cc),
                 * do not insert it into returnUrl
                 */

                return { url, replaced };
            }
            const urlReplaced = url.replace(from, to);
            return { url: urlReplaced, replaced: replaced || url !== urlReplaced };
        },
        { url: window.location.href, replaced: false }
    );
    return replaced ? url : null;
};

export function getExternalLoginUrl(locale: Language, withReturnUrl = false) {
    const noAuthRedirect = new URL(getACUrl());
    if (withReturnUrl) {
        const returnUrl = getReturnUrl();
        returnUrl && noAuthRedirect.searchParams.set('returnUrl', returnUrl);
    }
    noAuthRedirect.searchParams.set('locale', locale);
    return noAuthRedirect.toString();
}
